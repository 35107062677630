import { PATCH } from "@/core/services/store/request.module";

export default {
  methods: {
    patchContractor(contractor, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (contractor) {
          _this.$store
            .dispatch(PATCH, { url: "contractor/" + contractor, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Contractor is required");
        }
      });
    },
    patchContractorContactPerson(contractor, persons, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (contractor) {
          _this.$store
            .dispatch(PATCH, {
              url: "contractor/" + contractor + "/contact-person/" + persons,
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Contractor is required");
        }
      });
    },
    patchContractorProperty(contractor, properties, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (contractor) {
          _this.$store
            .dispatch(PATCH, {
              url:
                "contractor/" + contractor + "/property-address/" + properties,
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Contractor is required");
        }
      });
    },
    patchContractorBilling(contractor, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (contractor) {
          _this.$store
            .dispatch(PATCH, {
              url: "contractor/" + contractor + "/billing-address",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Contractor is required");
        }
      });
    },
  },
};
